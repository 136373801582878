import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import ViaCep from 'vue-viacep'
import VueMask from 'v-mask'
import './plugins/axios'
import excel from 'vue-excel-export'
import Camera from "simple-vue-camera";

Vue.use(excel)
Vue.use(ViaCep);
Vue.use(VueMask);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  Camera,
  render: h => h(App)
}).$mount('#app')
