<template>
  <v-container fluid>
    <v-row class="text-center">
      <!--         <v-col class="mb-4 mt-2" v-for="botao in botoes" :key="botao.ordem">
          
            <v-btn small fab v-on:click="acaoFunc(botao.ordem)" :color="botao.cor" :title="botao.texto"><v-icon color="white" class="ma-2">{{ botao.icone }}</v-icon></v-btn>
          
        </v-col> -->

      <v-col class="mb-4 mt-2">

        <v-btn small fab v-on:click="acaoFunc(1)" color="primary" title="RPA"><v-icon color="white"
            class="ma-2">mdi-file-document-plus-outline</v-icon></v-btn>

      </v-col>

      <v-col class="mb-4 mt-2">

        <v-btn small fab v-on:click="acaoFunc(2)" color="success" title="Colaboradores"><v-icon color="white"
            class="ma-2">mdi-account-hard-hat-outline</v-icon></v-btn>

      </v-col>

      <v-col class="mb-4 mt-2">

        <v-btn small fab v-on:click="acaoFunc(3)" color="orange" title="Dados"><v-icon color="white" class="ma-2">
            mdi-finance</v-icon></v-btn>

      </v-col>

      <v-col class="mb-4 mt-2">

        <v-btn small fab v-on:click="acaoFunc(4)" color="purple" title="Relatórios"><v-icon color="white"
            class="ma-2">mdi-poll</v-icon></v-btn>

      </v-col>

      <v-col class="mb-4 mt-2">

        <v-btn small fab v-on:click="acaoFunc(5)" color="error" title="Empresa"><v-icon color="white"
            class="ma-2">mdi-domain</v-icon></v-btn>

      </v-col>
      <v-col class="mb-4 mt-2" v-if="rpaNivelAcesso == 1 || rpaNivelAcesso == 6">

      <v-btn small fab v-on:click="acaoFunc(7)" color="lighten blue" title="Pagamentos RPA"><v-icon color="white"
            class="ma-2">mdi-qrcode</v-icon></v-btn>

      </v-col>      

      <v-col class="mb-4 mt-2" v-if="rpaNivelAcesso == 1">

        <v-btn small fab v-on:click="acaoFunc(6)" color="success" title="Usuários"><v-icon color="white"
            class="ma-2">mdi-account-multiple</v-icon></v-btn>

      </v-col>

    </v-row>
    <v-row>
      <v-card class="mx-auto">
        <RPATable />
      </v-card>
    </v-row>
    <v-dialog v-model="dialogUsuarios" max-width="900">

      <Usuario @fechar="dialogUsuarios = !dialogUsuarios"></Usuario>
    </v-dialog>

    <v-dialog v-model="dialogColaborador" max-width="500">

      <ColaboradorEdit @fechar="dialogColaborador = !dialogColaborador" v-if="dialogColaborador"></ColaboradorEdit>
    </v-dialog>
    <v-dialog v-model="dialogRPA" max-width="500">

      <RPAEdit @fechar="fecharRPA()" v-if="dialogRPA"></RPAEdit>

    </v-dialog>

    <v-dialog v-model="dialogRPATablePay">

      <RPATablePay @fechar="dialogRPATablePay = false" v-if="dialogRPATablePay"></RPATablePay>

    </v-dialog>



    <v-dialog persistent v-model="dialogReport" width="500">

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Relatório de RPAs
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2 justify-center">
            <v-text-field type="date" class="ma-1" v-model="datainicio" label="Data inicial"></v-text-field>
            <v-text-field type="date" class="ma-1" v-model="datafim" label="Data final"></v-text-field>
          </v-row>
          <v-row class="justify-center mt-2">
            <template>
              <v-data-table :headers="headers" :items="rpas" :items-per-page="5" class="elevation-1"></v-data-table>
            </template>
          </v-row>
          <v-row class="justify-center">
            <v-col>
              <export-excel v-if="rpas.length > 0" :data="rpas" type="xls" name="RPA Analitico">
                <v-btn fab small color="success" class="ma-4"><v-icon>mdi-download</v-icon></v-btn>
                <small>RPAs Analítico</small>
              </export-excel>
            </v-col>
            <v-col>
              <export-excel v-if="rpas2.length > 0" :data="rpas2" type="xls" name="RPA Sintetico">
                <v-btn fab small color="info" class="ma-4"><v-icon>mdi-download</v-icon></v-btn>
                <small>RPAs Sintético</small>
              </export-excel>
            </v-col>

          </v-row>
        </v-card-text>



        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" fab small @click="[listarRpas(), listarRpasSintetico()]">
            OK
          </v-btn>
          <v-btn color="orange" fab small @click="[dialogReport = false, limparRPA()]">
            <v-icon small color="white">mdi-logout</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ColaboradorEdit from './ColaboradorEdit.vue';
import Usuario from './Usuario.vue';
import RPAEdit from './RPAEdit.vue';
import RPATable from './RPATable.vue';
import RPATablePay from './RPATablePay.vue';



export default {
  name: 'HelloRPA',

  data: () => ({
    rpaNivelAcesso: localStorage.getItem('rpanivelacesso'),
    dialogColaborador: false,
    dialogUsuarios: false,
    dialogRPA: false,
    dialogRPATablePay : false,
    dialogReport: false,
    datainicio: '2024-03-01',
    datafim: '2024-03-31',
    botoes: [
      {
        ordem: 1,
        texto: 'RPA',
        cor: 'primary',
        icone: 'mdi-file-document-plus-outline',
        acao: 'acaoFunc()'
      },
      {
        ordem: 2,
        texto: 'Colaboradores',
        cor: 'success',
        icone: 'mdi-account-hard-hat-outline',
        acao: 'acaoFunc()'
      },
      {
        ordem: 3,
        texto: 'Dados',
        cor: 'orange',
        icone: 'mdi-finance',
        acao: 'acaoFunc()'
      },
      {
        ordem: 4,
        texto: 'Relatórios',
        cor: 'purple',
        icone: 'mdi-poll',
        acao: 'acaoFunc()'
      },
      {
        ordem: 5,
        texto: 'Empresa',
        cor: 'error',
        icone: 'mdi-domain',
        acao: 'acaoFunc()'
      },
      {
        ordem: 6,
        texto: 'Usuários',
        cor: 'success',
        icone: 'mdi-account-multiple',
        acao: 'acaoFunc()'
      },


    ],
    rpas: [],
    rpas2: [],
    headers: [
      {
        text: 'Colaborador',
        align: 'start',
        sortable: false,
        value: 'nome',
      },
      { text: 'Data inicial', value: 'datainicio' },
      { text: 'Data final', value: 'datafim' },
      { text: 'Setor de Trabalho', value: 'setor' },
      { text: 'Dias trab.', value: 'diastrabalhados' },
      { text: 'R$ Total', value: 'totalapagar' },
    ],
  }),
  components: {
    //Colaborador,
    //RPA,
    Usuario,
    RPAEdit,
    RPATable,
    RPATablePay,
    ColaboradorEdit
  },
  methods: {
    acaoFunc(i) {
      if (i == 1) {
        this.dialogRPA = true
      } else if (i == 4) {
        this.dialogReport = true

      } else if (i == 6) {
        this.dialogUsuarios = true
      }else if(i == 7){
        this.dialogRPATablePay = true
      } else {
        this.dialogColaborador = true
      }

    },
    async listarRpas() {
      //console.log(this.datainicio.length+ ' - '+this.datafim)
      if (this.datainicio.length == 10 & this.datafim.length == 10) {
        let body = {
          datainicio: this.datainicio,
          datafim: this.datafim
        }
        this.$http.post('/rpa/listarrpas', body).then(response => {
          if (response.status == 200) {
            this.rpas = response.data
            //console.log(this.rpas)
          }
        }).catch(e => {
          console.error(e)
        })
      } else {
        return
      }

    },
    async listarRpasSintetico() {
      //console.log(this.datainicio.length+ ' - '+this.datafim)
      if (this.datainicio.length == 10 & this.datafim.length == 10) {
        let body = {
          datainicio: this.datainicio,
          datafim: this.datafim
        }
        this.$http.post('/rpa/listarrpassintetico', body).then(response => {
          if (response.status == 200) {
            this.rpas2 = response.data
            //console.log(this.rpas2)
          }
        }).catch(e => {
          console.error(e)
        })
      } else {
        return
      }

    },
    limparRPA() {
      this.rpas = []
      this.rpas2 = []
      //this.datainicio = '',
      //this.datafim = ''
    },
    fecharRPA() {
      this.dialogRPA = !this.dialogRPA
      //window.location.reload()
    }


  },
  mounted() {
    if (!localStorage.getItem('rpatoken')) {
      window.location.href = '/login'
    }
  }
}
</script>