<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" colspan="2">
                <h1>Recibo de Pagamento Autônomo</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-text-field 
                label="% Perc. de Ocupação" 
                append-icon="mdi-percent"
                type="text"
                inputmode="numeric" 
                pattern="[0-9\s]{13,19}"               
                hint="% de ocupação / hospedagem (somente números)"
                persistent-hint 
                v-model="percOcupacao" 
                style="max-width: 200px!important;"
                :rules="numberMoreZero">
                </v-text-field>
              </td>
              <td>

              </td>
            </tr>
            <tr>
              <td>
                <v-text-field class="mt-2 mb-2" label="CPF" inputmode="numeric" pattern="[0-9\s]{13,19}"
                  append-icon="mdi-file-document" @keyup="buscarCPF()" @blur="buscarCPF()" v-model="documentoCPF"
                  style="max-width: 350px !important" :rules="cpfRules"></v-text-field>
              </td>
              <td class="text-center">
                <v-switch label="RPA Anormal" v-model="rpaAnormal"></v-switch>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <v-text-field class="mt-2 mb-2" label="Nome Completo" append-icon="mdi-account" v-model="nome" readonly
                  :rules="nomeCompletoRules"></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="text-center">
                <v-text-field class="mt-2 mb-2" label="Função / Cargo" append-icon="mdi-contacts"
                  v-model="cargoColaborador" id="cargoColaborador" :rules="nomeCompletoRules"></v-text-field>
              </td>

              <td class="text-center">
                <v-text-field class="mt-2 mb-2" label="Gestor Responsável" append-icon="mdi-account-convert"
                  v-model="supervisorResponsavel" :rules="nomeCompletoRules"></v-text-field>
              </td>

            </tr>
            <tr>
              <td colspan="2" class="text-center">
                <v-text-field class="mt-2 mb-2" label="Tipo de Fardamento" append-icon="mdi-tshirt-crew"
                  v-model="tipoFardamento"  :rules="nomeCompletoRules"></v-text-field>
              </td>
            </tr>

            <tr>
              <td>
                <v-text-field type="date" id="dtini" label="Primeiro dia de trabalho" v-model="dataInicio"
                  :rules="dataRules" @blur="verificaData()" @keyup="verificaData()" :hint="hintDataInicio" persistent-hint></v-text-field>
              </td>
              <td>
                <v-text-field type="date" id="dtfim" label="Último dia de trabalho" v-model="dataFim" :rules="dataRules"
                  @blur="calcularDias()"></v-text-field>
              </td>
            </tr>
            <tr v-if="dataInvalida">
              <td colspan="2">
                <small style="color: red">Data inicial não pode ser menor que hoje, verifique!</small>
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field type="time" label="Hora de chegada" v-model="horaInicio"
                  :rules="horasRules"></v-text-field>
              </td>
              <td>
                <v-text-field type="time" label="Hora de saída" v-model="horaFim" :rules="horasRules"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field label="Quadro efetivo necessário do setor" type="number" hint="nº total de funcionários"
                  persistent-hint v-model="numEfetivoNecessario" style="max-width: 150px!important;"
                  :rules="numberMoreZero">
                </v-text-field>
              </td>
              <td>
                <v-row justify="center">
                  <v-text-field class="ml-2 mr-2" label="Efetivo atual disponível do setor" type="number"
                  :hint="retornoHint" persistent-hint v-model="numEfetivoDisponivel"
                  style="max-width: 200px!important;" :rules="numberMoreZero" @keyup="calcularEfetivoSetor()">
                </v-text-field>
                </v-row>
              </td>
            </tr>
            <tr>
            <td colspan="2">
              <v-row justify="center">
                <h3>{{percEfetivoDisponivel}} do quadro efetivo.</h3>
              </v-row>
            </td>
          </tr>
            <tr>
              <td colspan="2">
                <v-select item-value="idsetor" :filterable="false" :items="setores" item-text="nome"
                  label="Setor de Trabalho" v-model="idSetor" :rules="setorRules"></v-select>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <v-textarea counter outlined clearable
                  :label="rpaAnormal ? 'Descreva o motivo e anormalidade' : 'Motivo'" v-model="motivo" variant="outlined"
                  rows="3" no-resize class="ma-2" :rules="motivoRules"></v-textarea>
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field type="number" label="Dias de trabalho" v-model="diasTrabalhados" readonly
                  :rules="diasTrabalhadosRules"></v-text-field>
              </td>
              <td>
                <v-text-field type="text" inputmode="numeric" pattern="[0-9\s]{13,19}" label="R$ Diária"
                  v-model="valorDiaria" :rules="valorDiariaRules" @keyup="calcularValorTotal()"></v-text-field>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <v-text-field type="number" label="R$ Total" readonly v-model="valorTotal"
                  :rules="valorTotalRules"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field type="number" label="% INSS" v-model="inss" readonly
                  :rules="aliquotasRules"></v-text-field>
              </td>
              <td>
                <v-text-field type="float" label="% ISS" v-model="iss" readonly :rules="aliquotasRules"></v-text-field>
              </td>
            </tr>
            <!--             <tr>
              <td colspan="2" class="text-xs-center">
                <v-checkbox
                  v-model="docConferido"
                  color="info"
                  label="Documento Conferido"
                  value="red"
                  hide-details
                ></v-checkbox>
              </td>
            </tr> -->
            <tr>
              <td colspan="2" class="text-center">
                <v-btn class="ma-2" color="error" @click="fechar()"><v-icon>mdi-close-octagon</v-icon></v-btn>
                <v-btn class="ma-2" color="success" @click="salvarRPA()"><v-icon>mdi-content-save-check</v-icon></v-btn>
              </td>
            </tr>
          </tbody>
          <v-dialog v-model="dialogOK" max-width="300">
            <v-card max-width="300">
              <v-card-title><v-icon color="success">mdi-check-circle</v-icon></v-card-title>
              <v-card-text>
                <v-row class="justify-center">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" colspan="2">
                            <h3>Criando jornadas...</h3>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-for="dia in retornoRPA" :key="dia.idjornada">
                        <tr class="mt-2">
                          <td class="text-center">
                            <h4>para o dia</h4>
                          </td>
                          <td class="text-center">
                            <h4>{{ dia.data }}</h4>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center"><v-icon color="success">mdi-check</v-icon></td>
                          <td><v-btn color="error" class="ma-1" small @click="deletarDia(dia.idjornada)">Folga</v-btn>
                          </td>

                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
              </v-card-text>
              <v-card-actions class="text-center">
                <v-spacer></v-spacer>
                <v-btn small fab @click="[(dialogOK = !dialogOK), fechar()]" color="info" class="mt-2 mb-4">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-simple-table>
    </v-form>
  </div>
</template>
<script>
import { cpf } from "cpf-cnpj-validator";
export default {
  name: "CadastroRPA",
  data: () => ({
    retornoRPA: [],
    percOcupacao : '',
    rpaAnormal: false,
    cargoColaborador : '',
    supervisorResponsavel : '',
    numEfetivoNecessario : 0,
    numEfetivoDisponivel : 0,
    retornoHint : 'nº total de funcionários disponíveis',
    percEfetivoDisponivel : '%',
    hintDataInicio : '',
    tipoFardamento : '',
    alternaJornadas: false,
    dialogOK: false,
    idColaborador: 0,
    iss: 5,
    inss: 11,
    diasTrabalhados: 0,
    valid: null,
    documentoCPF: "",
    nome: "",
    dataInicio: "",
    dataFim: "",
    horaInicio: "",
    horaFim: "",
    idSetor: 0,
    motivo: "",
    valorDiaria: "",
    valorTotal: 0,
    dataInvalida: false,
    docConferido: false,
    setores: [
      {
        id: 0,
        nome: "Alimentos e Bebidas",
      },
      {
        id: 1,
        nome: "Governança",
      },
      {
        id: 2,
        nome: "Segurança / Portaria",
      },
      {
        id: 3,
        nome: "Manutenção",
      },
      {
        id: 4,
        nome: "Recepção",
      },
      {
        id: 5,
        nome: "Tec da Informação",
      },
    ],
    nomeCompletoRules: [
      (value) => !!value || "Campo obrigatório, preencha corretamente",
      (value) => /[^0-9]/.test(value) || "Nomes não podem conter números.",
    ],
    cpfRules: [
      (value) => !!value || "Campo obrigatório, preencha corretamente",
      (value) => value.length == 11 || "11 caratéres obrigatórios",
      (value) => cpf.isValid(value) || "CPF Inválido",
    ],
    horasRules: [
      (value) => !!value || "Campo obrigatório, preencha corretamente",
      (value) => value.length == 5 || "Preencha corretamente.",
      //value =>  value.length > 3 || 'Preencha corretamente'
    ],
    dataRules: [(value) => value.length == 10 || "Preencha corretamente."],
    setorRules: [(value) => !!value || "Selecione um setor"],
    motivoRules: [
      (value) => !!value || "Informe o motivo da contratação do extra",
    ],
    diasTrabalhadosRules: [
      (value) => value > 0 || "Verifique as datas de início e fim",
    ],
    valorDiariaRules: [
      (value) => value > 0 || value.length > 0 || "Informe corretamente o valor da diária",
    ],
    valorTotalRules: [(value) => value > 0 || "Verifique os campos calculados"],

    aliquotasRules: [
      (value) => value > 0 || "As alíquotas devem ser maior que 0",
    ],
    numberMoreZero: [
      (value) => value > 0 || "Campo deve ser maior que zero",
    ]
  }),
  methods: {
    displaySelected() {
      alert(this.idSetor);
    },
    async verificaData(){
      if(this.dataInicio.length == 10){
        this.dataInvalida = false;
        const d1 = new Date(this.dataInicio);
        const d2 = new Date()
        const diffInMs =  Math.abs(d1.getTime() - d2.getTime());
        const diffInDays = Math.ceil( diffInMs / (1000 * 60 * 60 * 24) );

        if(diffInDays < 2 && this.rpaAnormal == false){
          this.hintDataInicio = "Considere marcar RPA Anormal"
        }else{
          this.hintDataInicio = ""
        }
      }else{
        return
      }


    },
    async deletarDia(idjornada) {
      console.log('JORNADA: ' + idjornada)
      if (idjornada > 0) {

        await this.$http.post('/rpa/deletadiajornada', { idjornada: idjornada }).then(response => {
          if (response.status == 200) {
            let someArray2 = this.retornoRPA;
            this.retornoRPA = someArray2.filter(el => el.idjornada !== idjornada);
            alert('Removido!')
          } else {
            alert('Não foi possível remover o dia!')
          }
        }).catch(e => {
          console.log(e)
        })

      }
    },
    async calcularEfetivoSetor(){
      let result = 0
      if(this.numEfetivoNecessario > 0 && this.numEfetivoDisponivel > 0){
        result = (this.numEfetivoDisponivel * 100) / this.numEfetivoNecessario
        result = result.toFixed(1)
        //this.retornoHint = "nº total de funcionários disponíveis: "+ result + "%"
        this.percEfetivoDisponivel = result + '%'
      }
      
      //alert(result)
    },
    async salvarRPA() {
      if (this.validate()) {
        const vlrIss = (this.valorTotal / 100) * this.iss;

        const vlrInss = (this.valorTotal / 100) * this.inss;

        const rpa = {
          idcolaborador: this.idColaborador,
          datainicio: this.dataInicio,
          datafim: this.dataFim,
          horainicioprev: this.horaInicio,
          horafimprev: this.horaFim,
          idsetor: this.idSetor,
          motivo: this.motivo.toUpperCase(),
          diastotal: this.diasTrabalhados,
          percocupacao : this.percOcupacao,
          rpaanormal : this.rpaAnormal,
          cargotrabalhado : this.cargoColaborador,
          gestorresponsavel : this.supervisorResponsavel,
          tipofardamento : this.tipoFardamento,
          qtdeefetivonecessario : this.numEfetivoNecessario,
          qtdeefetivodisponivel : this.numEfetivoDisponivel,
          valordia: this.valorDiaria.replace(".", ","),
          valortotal: this.valorTotal.replace(".", ","),
          iss: this.iss,
          inss: this.inss,
          valoriss: vlrIss.toFixed(2).replace(".", ","),
          valorinss: vlrInss.toFixed(2).replace(".", ","),
        };
        //console.log(JSON.stringify(rpa));
        await this.$http
          .post("/rpa/cadastrarrpa", rpa)
          .then((response) => {
            if (response.status == 200) {
              this.retornoRPA = response.data;
              for (let index = 0; index < this.retornoRPA.length; index++) {
                let dia = ''
                let mes = ''
                let ano = ''
                const element = this.retornoRPA[index];

                element.data = element.data.substring(0, 10)
                dia = element.data.substring(8, 10)
                mes = element.data.substring(5, 7)
                ano = element.data.substring(0, 4)
                element.data = dia + '/' + mes + '/' + ano

              }
              //console.log(response.data);
              this.dialogOK = true;

            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    buscarCPF() {
      this.documentoCPF = this.documentoCPF.replace(/[^0-9]/g, "");

      if (this.documentoCPF.length == 11 && cpf.isValid(this.documentoCPF)) {
        if (cpf.isValid(this.documentoCPF)) {
          this.$http
            .post("/auth/consultacpfcolaborador", {
              cpf: this.documentoCPF.replace(/[^0-9]/g, ""),
            })
            .then((response) => {
              if (response.status == 200) {
                this.idColaborador = response.data[0].idcolaborador;
                this.nome = response.data[0].nome;
                this.documentoCPF = response.data[0].cpf;
                document.getElementById("cargoColaborador").focus();

                return;
              }
            })
            .catch((e) => {
              console.error(e);
            });
        } else {
          console.log("CPF inválido");
        }
      }
    },
    async listarSetores() {
      await this.$http.get("/rpa/listarsetores").then((response) => {
        if (response.status == 200) {
          this.setores = response.data;
        }
      });
    },
    calcularDias() {
      if (this.dataInicio.length == 10 && this.dataFim.length == 10) {

        this.dataInvalida = false;
        const d1 = this.dataInicio;
        const d2 = this.dataFim;
        const diffInMs = new Date(d2) - new Date(d1);
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24) + 1;

        this.diasTrabalhados = diffInDays;

        if (this.alternaJornadas) {
          if (this.diasTrabalhados % 2 === 0) {

            alert('É par')
          } else {
            alert('É IMPAR')
            let d = new Date(d2);
            alert(d.setDate(d.getDate()) - 1)
            d.setDate(d.getDate()) - 1
            let month = (d.getMonth() + 1);
            if (month < 10) {
              month = '0' + month
            } else {
              month = '' + month

            }
            let day = '' + d.getDate();
            let year = d.getFullYear();
            this.dataFim = year + '-' + month + '-' + day
            alert(this.dataFim)
            this.diasTrabalhados--
          }

          this.diasTrabalhados = (this.diasTrabalhados / 2)
        }
      }
    },
    calcularValorTotal() {

      if (this.diasTrabalhados > 0 && this.valorDiaria.replace(',', '.') > 0) {
        this.valorTotal = this.diasTrabalhados * this.valorDiaria.replace(',', '.');
        this.valorTotal = this.valorTotal.toFixed(2)
      } else {
        this.valorTotal = 0;
      }
    },
    focusCampoData() {
      if (this.dataInicio.length == 10) {
        document.getElementById("dtfim").focus();
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    fechar() {
      this.$emit("fechar");
    },
  },
  mounted() {
    this.listarSetores();
  },
};
</script>