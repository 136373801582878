<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark v-if="token"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="../src/assets/eph-logo.svg"
          transition="scale-transition"
          width="100"
        />

        <h3>RPA Online</h3>
        
      </div>

      <v-spacer></v-spacer>

        <h4 class="mr-2 ml-2" v-if="token"> {{ nome }}</h4>
        <v-icon class="mr-2">mdi-help</v-icon>
        <v-icon class="ml-2" @click="logout()">mdi-power</v-icon>

      <!-- </v-btn> -->
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    token: localStorage.getItem('rpatoken') || false,
    nome : localStorage.getItem("nomeusuario") || '',
    nivelAcesso : localStorage.getItem("rpanivelacesso")

  }),
  methods:{
    logout(){
      localStorage.removeItem("rpatoken");
      localStorage.clear()
      setTimeout(() => {
        window.location.href = '/login'
      }, 1000);
    }
  },
  mounted(){
    if(this.token){
      //window.location.href = '/homerpa'
      console.log('Log in')
    }
  }
};
</script>
