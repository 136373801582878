<template>
    <v-data-table :headers="headers" :items="desserts" sort-by="calories" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Usuários</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="400px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" fab small class="mb-2" v-bind="attrs" v-on="on"
                            title="Adicionar Usuário">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
                                <v-container>
                                    <v-row>
                                        <v-col>
                                            <v-checkbox label="ATIVO" v-model="editedItem.ativo"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="editedItem.email" label="Email"
                                                append-icon="mdi-mail" :rules="emailRules" clearable></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-text-field v-model="editedItem.nome" label="Nome" append-icon="mdi-account"
                                            :rules="nomeCompletoRules"></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field v-model="editedItem.login" label="Login"
                                            append-icon="mdi-account-lock" :rules="noTextNull"></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-switch v-model="showFieldPass" v-if="editedItem.idusuario > -1" label="Alterar senha"></v-switch>
                                    </v-row>
                                    <v-row>
                                        <v-text-field v-model="editedItem.senha" label="Senha" append-icon="mdi-key"
                                            type="password" :rules="senhaNull" v-if="showFieldPass"></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field v-model="editedItem.senha2" label="Confirmação"
                                            append-icon="mdi-lock" type="password" :rules="senhaNull" v-if="showFieldPass"></v-text-field>

                                    </v-row>
                                    <v-row>
                                        <v-select
                                            :filterable="false" 
                                            :items="niveisAcesso"
                                            item-text="descnivelacesso" 
                                            item-value="idnivelacesso"
                                            label="Nível de Acesso" 
                                            v-model="editedItem.idnivelacesso"
                                            :rules="nivelAcessoRules" 
                                            style="width: 300px!important;">
                                        </v-select>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                Cancelar
                            </v-btn>
<!--                             <v-btn color="orange darken-1" text @click="showFieldPass = !showFieldPass" v-if="editedItem.idusuario > -1">
                                Ignorar Senha
                            </v-btn> -->
                            
                            <v-btn color="primary darken-1" @click="salvarUsuario()">
                                Salvar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Deseja realmente desativar esse usuário?</v-card-title>
                        <v-card-text>
                            <v-row justify="center" class="ma-4">
                                <div >
                                    <h3>{{ editedItem.nome }}</h3>
                                </div>
                                
                            </v-row>
                           
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                            <v-btn color="red darken-1" text @click="deleteItemConfirm">SIM</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)" title="Editar Usuário" color="success">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" title="Desativar Usuário" color="error">
                mdi-cancel
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="listarUsuarios()">
                Recarregar
            </v-btn>
        </template>
    </v-data-table>
</template>
<script>
export default {
    data: () => ({
        valid: null,
        dialog: false,
        dialogDelete: false,
        showFieldPass : false,
        headers: [
            {
                text: 'Nome',
                align: 'start',
                sortable: false,
                value: 'nome',
            },
            { text: 'Email', value: 'email' },
            { text: 'Usuário', value: 'login' },
            { text: 'Nível', value: 'descnivelacesso' },
            { text: 'Ativo', value: 'ativotxt' },
            { text: 'Ações', value: 'actions', sortable: false },
        ],
        desserts: [],
        niveisAcesso: [
            {
                idnivel: 1,
                descnivel: "Admin",
            },
            {
                idnivel: 2,
                descnivel: "Segurança / Portaria",
            },
            {
                idnivel: 3,
                descnivel: "Setores em geral",
            },
            {
                idnivel: 4,
                descnivel: "RH / DP",
            },
            {
                idnivel: 4,
                descnivel: "Contabilidade",
            },
        ],

        editedIndex: -1,
        editedItem: {
            idusuario : -1,
            ativo: true,
            nome: '',
            email: '',
            login: '',
            idnivelacesso: 0,
            senha: '',
            senha2: '',
            calories: 0,
            fab: 0,
            carbs: 0,
            protein: 0,
        },
        defaultItem: {
            idusuario : -1,
            ativo: true,
            nome: '',
            email: '',
            login: '',
            idnivelacesso: 0,
            senha: '',
            senha2: '',
            calories: 0,
            fat: 0,
            carbs: 0,
            protein: 0,
        },
        noTextNull: [
            value => !!value || 'Campo obrigatório.'
        ],
        senhaNull: [
            value => !!value || 'Campo obrigatório.'
        ],
        emailRules: [
            (v) => !!v || "E-mail é obrigatório",
            (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        ],
        nomeCompletoRules: [
            value => !!value || 'Campo obrigatório, preencha corretamente',
            value => (/[^0-9]/.test(value)) || 'Nomes não podem conter números.'

        ],
        nivelAcessoRules: [
            value => !!value || 'Campo obrigatório, selecione um nivel'

        ]
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Adicionar Usuário' : 'Editar Usuário'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {

        //this.initialize()
        this.listarUsuarios()
        this.listarNiveisAcesso()

    },
    mounted() {
        this.listarUsuarios()
        this.listarNiveisAcesso()
    },

    methods: {
        validate() {
            return this.$refs.form.validate();
        },
        async salvarUsuario(){
            if(this.validate()){
                if(this.editedItem.senha !== this.editedItem.senha2){
                alert('Senhas não conferem\n Verifique!')
                return
                }else{
                    let usuario = {
                        nome : this.editedItem.nome,
                        login : this.editedItem.login,
                        email : this.editedItem.email,
                        
                        idnivelacesso : this.editedItem.idnivelacesso,
                        ativo : this.editedItem.ativo
                    }

                    if(this.editedItem.idusuario > -1){

                        usuario.idusuario = this.editedItem.idusuario

                    }

                    if(this.showFieldPass && this.editedItem.senha.length > 0){
                        usuario.senha = this.editedItem.senha
                    }

                    //SALVAR
                    this.$http.post('/auth/cadastrausuario', usuario).then(response =>{
                        if(response.status == 200){
                            this.dialog = false
                            this.listarUsuarios()
                            this.editedItem.idusuario = -1
                        }else{
                            alert('Erro salvar o usuário. ERROR: '+response.status)
                        }
                    }).catch(e =>{
                        console.error(e)
                    })


                }
            }


        },
        async listarUsuarios() {
            await this.$http.get('/auth/listausuarios').then(response => {
                if (response.status == 200) {
                    console.log(response.data)
                    this.desserts = response.data
                } else {
                    console.log('Não foi possível listar usuário. ERROR CODE: ' + response.status)
                }
            }).catch(e => {
                console.error(e)
            })
        },
        async listarNiveisAcesso(){
            await this.$http.get('/auth/listaniveisacesso').then(response => {
                if (response.status == 200) {
                    this.niveisAcesso = response.data
                }
            }).catch(e => {
                console.error(e)
            })
        },
        initialize() {
            this.desserts = [
                {
                    nome: 'Frozen Yogurt',
                    login: 159,
                    email: 6.0,
                    nivelacesso: 24,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
                {
                    nome: 'Ice cream sandwich',
                    login: 237,
                    email: 9.0,
                    nivelacesso: 37,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
                {
                    nome: 'Eclair',
                    login: 262,
                    email: 16.0,
                    nivelacesso: 23,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
                {
                    nome: 'Cupcake',
                    login: 305,
                    email: 3.7,
                    nivelacesso: 67,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
                {
                    nome: 'Gingerbread',
                    login: 356,
                    email: 16.0,
                    nivelacesso: 49,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
                {
                    nome: 'Jelly bean',
                    login: 375,
                    email: 0.0,
                    nivelacesso: 94,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
                {
                    nome: 'Lollipop',
                    login: 392,
                    email: 0.2,
                    nivelacesso: 98,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
                {
                    nome: 'Honeycomb',
                    login: 408,
                    email: 3.2,
                    nivelacesso: 87,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
                {
                    nome: 'Donut',
                    login: 452,
                    email: 25.0,
                    nivelacesso: 51,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
                {
                    nome: 'KitKat',
                    login: 518,
                    email: 26.0,
                    nivelacesso: 65,
                    ativo: true,
                    ativotxt: 'S',
                    senha: '',
                    senha2: ''
                },
            ]
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
                this.desserts.push(this.editedItem)
            }
            this.close()
        },
    },
}
</script>