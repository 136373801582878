<template>
  <div>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
  <v-simple-table dense >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-center" colspan="2">
            <h1>Cadastro de Colaborador</h1>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th colspan="2" class="text-center">
          <h2>Dados Pessoais</h2>
          </th>
        </tr>
        <tr>
          <td><v-text-field class="mt-2" label="CPF"  append-icon="mdi-file-document" v-model="documentoCPF" @keyup="buscarCPF()" :rules="cpfRules" style="width: 140px!important;"></v-text-field></td>
          <td><v-text-field class="mt-2" label="RG" append-icon="mdi-file-document" v-model="rg" :rules="rgRules" style="width: 140px!important;"></v-text-field></td>
        </tr>
        <tr >
          <td colspan="2" class="center-text"><v-text-field class="mt-2" label="Nome Completo" append-icon="mdi-account" :rules="nomeCompletoRules" v-model="nome" style="max-width: 400px!important;" ></v-text-field></td>
        </tr>        
        <tr>
          <td><v-text-field type="date" label="Nascimento" style="width: 100px!important;" v-model="dataNascimento" :rules="dataNascRules" ></v-text-field></td>
          <td><v-text-field type="text" label="Nº PIS" :rules="noTextNull" v-model="pis" style="width: 140px!important;"></v-text-field></td>
        </tr>
        <tr>
          <td><v-text-field type="text" label="Telefone" v-mask="'(##) # ####-####'" append-icon="mdi-phone" style="width: 160px!important; font-size: 13px!important;" v-model="telefone" :rules="telefoneRules"></v-text-field></td>
          <td><v-text-field type="text" label="Chave PIX" append-icon="mdi-qrcode" placeholder="Tel, CPF(Somente números) ou e-mail" v-model="chavePix" :rules="noTextNull" style="max-width: 250px!important;"></v-text-field></td>
        </tr>
        <tr>
          <td class="text-center" style="height: 150px!important;"><v-icon v-if="imgDoc1.length < 1" x-large>mdi-camera</v-icon><v-img v-else :src="imgDoc1" height="150" width="200" /></td>
          <td class="text-center" style="height: 150px!important;"><v-icon v-if="imgDoc1.length < 1" x-large>mdi-camera</v-icon><v-img v-else :src="imgDoc2" height="150" width="200" /></td>
        </tr>
        <tr>
          <td class="text-center">
            <!-- <v-file-input hide-input accept="image/*" label="Imagem 1" v-model="imgDoc1Model" @change="submitFiles(imgDoc1Model)"></v-file-input> -->
            <input
                  @change="setImage"
                  type="file"
                  name="photo"
                  accept="image/*"
                  style="width: 120px!important"
                />
            
          </td>
          <td class="text-center">
            <input
                  @change="setImage2"
                  type="file"
                  name="photo"
                  accept="image/*"
                  style="width: 120px!important"              
                />
          </td>
          
        </tr>
        <tr>
          <th colspan="2" class="text-center"> <h2>Endereço</h2></th>
        </tr>
        <tr class="text-center">
          <td colspan="2"><v-text-field @keyup="buscaCEP()" type="text" label="CEP" append-icon="mdi-numeric" v-mask="'#####-###'" v-model="cep" style="width: 120px!important; font-size: 13px!important;" :rules="cepRules"></v-text-field></td>
        </tr>
        <tr>
          <td><v-text-field type="text" label="Logradouro" v-model="logradouro" :rules="logradouroRules" :readonly="readonlyLogradouro"></v-text-field></td>
          <td><v-text-field id="numEnd" type="text" label="Nº" style="width: 50px!important;" v-model="numeroEndereco" :rules="noTextNull"></v-text-field></td>
        </tr>
        <tr>
          <td colspan="2">
            <v-text-field
              type="text"
              label="Bairro"
              v-model="bairro"
              :rules="bairroRules"
              style="max-width: 400px!important;"
              :readonly="readonlyBairro">
            </v-text-field>
          </td>
        </tr>
        <tr>
          <td>
            <v-text-field
              type="text"
              label="Cidade"
              v-model="cidade"
              :rules="cidadeRules"
              readonly>
            </v-text-field>
          </td>
          <td>
            <v-text-field
              type="float"
              label="UF"
              style="width: 80px!important;"
              v-model="uf"
              :rules="ufRules"
              readonly>
            </v-text-field>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="text-center">
            <v-btn class="ma-2" color="error" @click="[limpar(), limpar(), fechar()]"><v-icon>mdi-close-octagon</v-icon></v-btn> 
            <v-btn class="ma-2" color="success" @click="salvarColaborador()"><v-icon>mdi-content-save-check</v-icon></v-btn>
            <!-- <v-btn class="ma-2" color="success" @click="salvarColaborador()"><v-icon>mdi-content-save-check</v-icon></v-btn> -->
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-form>
  </div>
</template>
<script>
import { cpf } from 'cpf-cnpj-validator';
//import { ref } from '@vue/composition-api'
export default {
    name : 'ColaboraRPA',
    data: () => ({
        readonlyBairro : true,
        readonlyLogradouro : true,
        passo : 1,
        imgDoc1 : '',
        imgDoc2 : '',
        imgDoc1Model : '',
        imgDoc2Model : '',
        idcolaborador : 0,
        nome : '',
        documentoCPF : '',
        rg : '',
        pis : '',
        dataNascimento : '',
        valid : null,
        cep : '',
        logradouro : '',
        numeroEndereco : '',
        bairro : '',
        cidade : '',
        uf : '',
        telefone : '',
        chavePix : '',
        nomeCompletoRules : [
          value => !!value || 'Campo obrigatório, preencha corretamente',
          value => (/[^0-9]/.test(value)) || 'Nomes não podem conter números.'
          
        ],
        cpfRules : [
          value => !!value || 'Campo obrigatório, preencha corretamente',
          value =>  value.length == 11 || '11 caratéres obrigatórios',
          value => cpf.isValid(value) || 'CPF Inválido'
        ],
        rgRules : [
          value => !!value || 'Campo obrigatório, preencha corretamente',
          //value =>  value.length > 3 || 'Preencha corretamente'
        ],
        dataNascRules : [
          value => value.length == 10 || 'Preencha corretamente.'
        ],
        logradouroRules : [
          value => !!value || 'Campo obrigatório',
          
        ],
        telefoneRules :[
          value => !!value || 'Campo obrigatório',
          value => value.replace(/[^0-9]/g,'').length == 11 || 'Celular inválido' 
        ],
        bairroRules :[
          value => !!value || 'Campo obrigatório',
          
        ],
        cidadeRules :[
          value => !!value || 'Campo obrigatório',
          
        ],
        ufRules :[
          value => !!value || 'Campo obrigatório',
          value => value.length == 2 || 'UF inválida' 
        ],                           
        cepRules :[
          value => !!value || 'Campo obrigatório',
          value => value.replace(/[^0-9]/g,'').length == 8 || 'CEP inválido',
        ],
        pixRules : [
        value => !!value || 'Campo obrigatório',
        ],
        noTextNull :[
          value => !!value || 'Campo obrigatório.'
        ]


    }),
    methods :{




      async setImage(e) {
      const file = e.target.files[0];
      //console.log(file);

      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgDoc1 = event.target.result;

        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }

    },


    async setImage2(e) {
      const file = e.target.files[0];
      //console.log(file);

      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgDoc2 = event.target.result;

        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }

    },
      async salvarColaborador(){

        if(this.validate()){
         const colaborador = {
              
              "nome" : this.nome.toUpperCase(), 
              "cpf" : this.documentoCPF.replace(/[^0-9]/g,''),
              "rg" : this.rg, 
              "pis" : this.pis,
              "chavepix" : this.chavePix.toLowerCase(),
              "cep" : this.cep.replace(/[^0-9]/g,''),
              "logradouro" : this.logradouro.toUpperCase(),
              "numeroendereco" : this.numeroEndereco.toUpperCase(), 
              "bairro" : this.bairro.toUpperCase(),
              "cidade" : this.cidade.toUpperCase(), 
              "uf" : this.uf.toUpperCase(),
              "telefone" : this.telefone.replace(/[^0-9]/g,''),
              "datanasc" : this.dataNascimento,
              "imgdoc1"  : this.imgDoc1,
              "imgdoc2"  : this.imgDoc2,
          }

          //console.log(JSON.stringify(colaborador))
          if(this.idcolaborador > 0){
            colaborador.idcolaborador = this.idcolaborador
          }
          await this.$http.post('/auth/cadastracolaborador', colaborador, {
            headers: { "Content-Type": "application/json" }
          }).then(response =>{
            if(response.status == 200){
              this.limpar()
              this.$emit('fechar')
            }
          })
        }else{
          return
        }

      },
      limpar(){
        this.idcolaborador = 0
        this.nome = '' 
        this.documentoCPF = ''
        this.rg = ''
        this.chavePix = ''
        this.pis = ''
        this.cep = ''
        this.logradouro = ''
        this.numeroEndereco = '' 
        this.bairro = ''
        this.cidade = '' 
        this.uf = ''
        this.telefone = ''
        this.dataNascimento = ''            
      },
        fechar(){
            this.$emit('fechar')
        },
        buscaCEP(){
          const zip = this.cep.replace(/[^0-9]/g,'')
          if(zip.length == 8){
            
              this.$viaCep.buscarCep(this.cep).then((obj) => {
                      //console.log(obj)

                      if(obj.logradouro.length > 0){
                        this.logradouro = obj.logradouro
                      }else{
                        this.logradouro = 'RUA PROJETADA'
                        this.readonlyLogradouro = false
                      }
                      
                      if(obj.bairro.length > 0){
                        this.bairro = obj.bairro
                      }else{
                        this.bairro = 'CENTRO'
                        this.readonlyBairro = false
                      }

                      this.uf = obj.uf
                      this.cidade = obj.localidade
                      


                  
                      document.getElementById("numEnd").focus();
              });
          }

        },
        buscarCPF(){

            this.documentoCPF = this.documentoCPF.replace(/[^0-9]/g,'')

            if(this.documentoCPF.length == 11 && cpf.isValid(this.documentoCPF)){
                if(cpf.isValid(this.documentoCPF)){
                  this.$http.post('/auth/consultacpfcolaborador', { "cpf" : this.documentoCPF.replace(/[^0-9]/g,'') }).then(response =>{
                    if(response.status == 200){
                      this.idcolaborador = response.data[0].idcolaborador
                      this.nome = response.data[0].nome
                      this.documentoCPF = response.data[0].cpf
                      this.rg = response.data[0].rg
                      this.chavePix = response.data[0].chavepix
                      this.pis = response.data[0].pis
                      this.cep = response.data[0].cep
                      this.logradouro = response.data[0].logradouro
                      this.numeroEndereco = response.data[0].numeroendereco
                      this.bairro = response.data[0].bairro
                      this.cidade = response.data[0].cidade 
                      this.uf = response.data[0].uf
                      this.telefone = response.data[0].telefone
                      this.dataNascimento = response.data[0].datanasc.substring(0, 10)  
                      if(response.data[0].imgdoc1.length > 0){
                        this.imgDoc1 = response.data[0].imgdoc1
                      }
                      if(response.data[0].imgdoc2.length > 0){
                        this.imgDoc2 = response.data[0].imgdoc2
                      }
                      return
                    }
                  }).catch(e=>{
                    console.error(e)
                  })
                }else{
                    console.log("CPF inválido")
                }
            }
        },
        validate() {
          return this.$refs.form.validate();
        }
    },
/*     created(){
      document.getElementById("img1")[0].addEventListener("change", this.previewFile);
    } */
    
}
</script>