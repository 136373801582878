<template>
  <v-data-table :headers="headers" :items="desserts" :search="search" class="elevation-1">
    <template v-slot:top>

      <v-toolbar flat>
        <h3>Pagamemtos RPAs</h3><v-spacer></v-spacer><v-icon color="error" @click="fechar()">mdi-close-circle</v-icon>
      </v-toolbar>
      <v-toolbar flat class="elevation-1">
        <v-toolbar-title style="width: 250px!important;"><small>RPAs inseridas</small> </v-toolbar-title>
        <br>


        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field class="ma-4" style="max-width: 350px!important;" type="date" label="Data" v-model="dataConsulta"
          single-line hide-details></v-text-field>
        <v-btn fab @click="listarJornadasPorDataInsercao()" x-small color="success"
          title="Consultar por data de inserção"><v-icon>mdi-magnify</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field class="ma-4" style="max-width: 350px!important;" v-model="cpfConsulta"
          append-icon="mdi-file-account" label="CPF Colaborador" @keyup="listarJornadasPorCPF()" single-line
          hide-details></v-text-field>
        <v-btn fab @click="listarJornadasPorCPF()" x-small color="success"
          title="Consultar por CPF do colaborador"><v-icon>mdi-magnify</v-icon></v-btn>

        <v-dialog v-model="dialogEntrada" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Confirmação</v-card-title>
            <v-card-text>
              <v-row justify="center" class="ma-2">
                <v-icon v-if="imgCheckin.length < 1" x-large>mdi-camera</v-icon>
                <v-img v-else :src="imgCheckin" height="200" width="200" />
              </v-row>
              <v-row justify="center" class="ma-2">
                <input @change="setImageCkeckin" type="file" name="photo" accept="image/*"
                  style="width: 120px!important" />
              </v-row>
              <v-row justify="center" class="ma-2">
                <h3>Confirma a <strong style="color: green;">ENTRADA</strong> de </h3>
                <br />
                <h3>{{ editedItem.nome }}?</h3>
              </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="white--text" color="deep-orange lighten-2" small
                @click="dialogEntrada = !dialogEntrada">Cancelar</v-btn>
              <v-btn color="primary" :disabled="!imgCheckin.length > 0" small @click="atualizaJornada('I')">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogImgDoc" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Documento</v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-img :src="imgDoc1" height="400" width="400" class="ma-2"></v-img>
                <v-img :src="imgDoc2" height="400" width="400" class="ma-2"></v-img>
              </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="white--text" color="deep-orange lighten-2" small @click="closeImgDoc">Cancelar</v-btn>
              <v-btn color="primary" small @click="closeImgDoc">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDetalhesRPA" max-width="500">

          <DetalhesRPA @fechar="dialogDetalhesRPA = !dialogDetalhesRPA" :idrpa="idrpa" v-if="dialogDetalhesRPA">
          </DetalhesRPA>

        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Confirmação</v-card-title>
            <v-card-text>
              <v-row justify="center" class="ma-2">
                <v-icon v-if="imgCheckout.length < 1" x-large>mdi-camera</v-icon>
                <v-img v-else :src="imgCheckout" height="200" width="200" />
              </v-row>
              <v-row justify="center" class="ma-2">
                <input @change="setImageCkeckout" type="file" name="photo" accept="image/*"
                  style="width: 120px!important" />
              </v-row>
              <v-row justify="center" class="ma-2">

                <h3>Confirma a <strong style="color: red;">SAÍDA</strong> de </h3>

                <br />
                <h3>{{ editedItem.nome }}?</h3>

              </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="white--text" color="deep-orange lighten-2" small
                @click="dialogDelete = !dialogDelete">Cancelar</v-btn>
              <v-btn color="primary" :disabled="!imgCheckout.length > 0" small @click="atualizaJornada('T')">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div style="display: flex; flex-direction: row!important;">
        <v-btn
        x-small 
        fab
        color="success"
        class="mr-1"
        title="Confirmar Pagamento"
        
        :disabled="item.statuspagamento != 'PD'"
        >
        <v-icon
          small
          color="white"
        >
          mdi-cash-plus
        </v-icon>
        </v-btn>
        <v-btn class="ml-1 mr-1" x-small fab color="info" title="Consultar Documento do Colaborador">
          <v-icon small color="white" @click="abrirDocumento(item)">
            mdi-file-eye-outline
          </v-icon>


        </v-btn>
        <v-btn class="ml-1 mr-1" x-small fab color="orange" title="RPA - Ver mais...">
          <v-icon small color="white" @click="abrirDetalhesRPA(item)">
            mdi-eye-plus
          </v-icon>
        </v-btn>

        <v-btn class="ml-1 mr-1" x-small fab color="black" title="Gerar QrCode">
          <v-icon small color="white" @click="abrirQrCode(item)">
            mdi-qrcode
          </v-icon>
        </v-btn>
        <v-btn class="ml-1 mr-1" x-small fab color="purple" title="Copiar chave PIX">
          <v-icon small color="white" >
            mdi-content-copy
          </v-icon>
        </v-btn>
      </div>
      <v-dialog v-model="dialogQrCode" max-width="300" persistent>

        <!--QrCodePix @fechar="dialogQrCode = !dialogQrCode"></QrCodePix-->
        <v-card>
          <v-card-title>Qr-Code <v-spacer></v-spacer><v-icon color="red" @click="dialogQrCode = false">mdi-close-circle</v-icon></v-card-title>
          <v-img :src="qrCodePixImg" width="300" height="300"></v-img>
          <v-text-field
          class="ma-2"
          label="PIX copia e cola"
          v-model="qrCodePixCopiaCola"
          readonly
          solo
          ></v-text-field>
        </v-card>
        
      </v-dialog>

    </template>



  </v-data-table>
</template>
<script>
import DetalhesRPA from "./DetalhesRPA.vue";
import { QrCodePix } from 'qrcode-pix';
export default {
  name: "RPATable",
  data: () => ({
    dialog: false,
    dialogQrCode : false,
    qrCodePix : '',
    qrCodePixCopiaCola : '',
    qrCodePixImg : '',
    dataConsulta: '',
    cpfConsulta: '',
    dialogDelete: false,
    dialogEntrada: false,
    dialogImgDoc: false,
    dialogDetalhesRPA: false,
    imgCheckin: '',
    imgCheckout: '',
    imgDoc1: '',
    imgDoc2: '',
    idrpa: 0,
    search: "",
    headers: [
      { text: "Nº RPA", align: "center", value: "idrpa" },
      {
        text: "Nome Completo",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf", align: "center" },
      { text: "Setor", value: "descsetor", align: "start" },
      { text: "DATA INI", value: "datainicio", align: "center" },
      { text: "DATA FIM", value: "datafim", align: "center" },
      { text: "Status Pgto", value: "statuspagamento", align: "center" },
      { text: "Chave PIX", value: "chavepix", align: "center" },
      { text: "Valor", value: "valortotal", align: "center" },
      { text: "Ações", value: "actions", sortable: false, align: "center" },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),
  components: {
    DetalhesRPA
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getDataAtual()
    this.listarJornadasPorDataInsercao();
  },

  methods: {
    async setImageCkeckin(e) {
      const file = e.target.files[0];
      //console.log(file);

      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgCheckin = event.target.result;

        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }

    },
    fechar() {

      this.$emit("fechar")

    },
    async setImageCkeckout(e) {
      const file = e.target.files[0];
      //console.log(file);

      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgCheckout = event.target.result;

        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }

    },
    abrirDetalhesRPA(item) {
      this.idrpa = item.idrpa;
      //alert(this.idrpa)
      this.dialogDetalhesRPA = true;

    },
    async abrirQrCode(item) {
      this.idrpa = item.idrpa;
      //alert(this.idrpa)

      this.dialogQrCode = true;
      this.qrCodePix = QrCodePix({
          version: '01',
          key: item.chavepix, //or any PIX key
          name: 'nome',
          city: 'Natal',
          transactionId: 'RPA:'+item.idrpa, //max 25 characters
          message: 'PgtoRPA'+item.idrpa,
          cep: '59090050',
          value: 160.00,
      });
      //alert(this.qrCodePix.payload())
      this.qrCodePixCopiaCola = await this.qrCodePix.payload()
      this.qrCodePixImg = await this.qrCodePix.base64()
    },
    async listarJornadasPorCPF() {
      if (this.cpfConsulta.length != 11) {
        return
      } else {
        await this.$http
          .post("/rpa/listarrpasporcpf", { cpf: this.cpfConsulta })
          .then(response => {
            if (response.status == 200) {
              this.desserts = response.data;
              return;
            }
          })
          .catch(e => {
            console.error(e);
          });
      }

      this.imgCheckin = ''
      this.imgCheckout = ''
    },
    async listarJornadasPorDataInsercao() {
      if (this.dataConsulta.length < 6) {
        return
      } else {
        await this.$http
          .post("/rpa/listarrpaspordatainsercao", { data: this.dataConsulta })
          .then(response => {
            if (response.status == 200) {
              if (response.data.length > 0) {
                //this.desserts = response.data;
                this.desserts = []
                for (let index = 0; index < response.data.length; index++) {
                  //const element = array[index];
                  if(response.data[index].validadorh == true){
                    this.desserts.push(response.data[index])
                  }
                  
                }
                //console.log(response.data)
              } else {
                this.desserts = []
              }

              return;
            } else {
              this.desserts = []
            }
          })
          .catch(e => {
            console.error(e);
          });
      }

      this.imgCheckin = ''
      this.imgCheckout = ''
    },
    async buscarDocColaborador(cpf) {
      //alert(cpf)
      if (cpf.length == 11) {
        this.$http.post('/auth/buscardocumentocolaborador', { cpf: cpf }).then(response => {
          if (response.status == 200) {
            this.imgDoc1 = response.data[0].imgdoc1
            this.imgDoc2 = response.data[0].imgdoc2
          }
        }).catch(e => {
          console.error(e)
        })
      }
    },
    modalAtualizaJornada(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEntrada = true;
      console.log(status);
    },

    saidaItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    abrirDocumento(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      //alert(JSON.stringify(item))
      this.buscarDocColaborador(item.cpf)
      this.dialogImgDoc = true;
    },

    atualizaJornada(status) {
      const jornada = {
        status: status,
        idjornada: this.editedItem.idjornada,

      };

      if (status == 'I') {
        jornada.imgcheckin = this.imgCheckin
      }

      if (status == 'T') {
        jornada.imgcheckout = this.imgCheckout
      }

      this.$http.post("/rpa/atualizajornada", jornada).then(response => {
        if (response.status == 200) {
          this.editedIndex = -1;

          this.dialogDelete = false;

          this.dialogEntrada = false;

          this.listarJornadasHoje();
        }
      });

      this.editedItem = Object.assign({}, this.defaultItem);
    },
    closeImgDoc() {
      this.dialogImgDoc = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getDataAtual() {
      let dataAtual = new Date();
      let anoAtual = dataAtual.getFullYear();
      let mesAtual = dataAtual.getMonth() + 1

      if (mesAtual < 10) {
        mesAtual = '0' + mesAtual
      }
      let diaAtual = dataAtual.getDate();

      if(diaAtual < 10){
        diaAtual = '0'+diaAtual
      }

      this.dataConsulta = anoAtual + '-' + mesAtual + '-' + diaAtual
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>