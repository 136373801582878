<template>
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
           <v-img
            :src="require('../assets/logoeph1.png')"
            contain
            max-height="100"
          />
          <!-- <v-icon x-large class="ma-4" color="success">mdi-account-circle</v-icon> -->
          <h5 class="display-1 mb-3 mt-4">
            Seja bem vindo
          </h5>
        </v-col>
  
        <v-col class="mb-4 text-center">
<!--           <h1 class="display-2 font-weight-bold mb-3">
            RPA Online
          </h1> -->
          <div style="max-width: 350px;  margin: 0 auto;" class="text-xs-center">
          <v-row class="subheading font-weight-regular ma-2">
            <v-text-field append-icon="mdi-account" label="Login" variant="solo-filled" v-model="nomeusuario"></v-text-field>
          </v-row>
            <div style="height: 20px!important;">
              <span style="color: red;">{{ msgRetorno }}</span>
            </div>
            <v-row class="subheading font-weight-regular ma-2">
            <v-text-field append-icon="mdi-key" type="password" label="Senha" variant="solo-filled" style="width: 3000px!important;" v-model="senha"></v-text-field>
            
            <br>
            <v-spacer><a href="mailto:savio.mauricio@gmail.com">Não tenho conta de usuário.</a></v-spacer>
            
            

          </v-row>
        </div>
        </v-col>
  
        <v-col
          class="mb-5"
          cols="12"
        >

  
          <v-row justify="center">
            <v-btn color="primary" @click="login()">Entrar</v-btn>
          </v-row>
        </v-col>
  
        <v-col
          class="mb-5"
          cols="12"
        >

  
          <v-row justify="center">

          </v-row>
        </v-col>
  
        <v-col
          class="mb-5"
          cols="12"
        >
          <h4 class="mb-3">
            Por Maurício Sávio
          </h4>
  
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
    export default {
      name: 'LoginRPA',
  
  
      data: () => ({
        nomeusuario : '',
        senha : '',
        msgRetorno : ''
      }),
      methods : {
        async login(){

          if(this.nomeusuario.length > 0 && this.senha.length > 0){
            this.nomeusuario = this.nomeusuario.toLowerCase()
            await this.$http.post('/auth/loginusuario', { nomeusuario : this.nomeusuario, senha : this.senha },
            {
              headers: { "Content-Type": "application/json" },
            },            
            ).then(response =>{
              if(response.status == 200){
                this.senha = "";
                localStorage.setItem("rpatoken", response.data.token);
                localStorage.setItem("emailrpauser", response.data.email);
                localStorage.setItem("rpanivelacesso", response.data.idnivelacesso);
                localStorage.setItem("nomeusuario", response.data.nome)
                setTimeout(() => {
                  window.location.href = '/'
                }, 1000);
              }
            }).catch( e =>{
              this.msgRetorno = 'Dados inválidos.'
              this.senha = ''
              setTimeout(() => {
                this.msgRetorno = ''
              }, 3000);
              console.log(e)
            })
          }else{
            this.msgRetorno = 'Preencha corretamente usuário e senha.'
            setTimeout(() => {
                this.msgRetorno = ''
              }, 3000);
            return
          }

            //window.location.href = '/homerpa'

        }
      }
    }
  </script>
  