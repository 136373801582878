<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
    <v-toolbar elevation="0">
      <h3>Recibo de Pagamento Autônomo</h3>
      <v-spacer></v-spacer>
      <v-icon color="error" @click="fechar()">mdi-close-circle</v-icon>
    </v-toolbar>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              <v-text-field
                label="% Perc. de Ocupação"
                append-icon="mdi-percent"
                type="text"
                inputmode="numeric"
                hint="% de ocupação / hospedagem"
                persistent-hint
                v-model="percOcupacao"
                style="max-width: 100px !important"
                readonly
              >
              </v-text-field>
            </td>
            <td>
              <v-switch
                label="RPA Anormal"
                v-model="rpaAnormal"
                readonly
              ></v-switch>
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                class="mt-2 mb-2"
                label="CPF"
                append-icon="mdi-file-document"
                v-model="documentoCPF"
                style="max-width: 350px !important"
                readonly
              ></v-text-field>
            </td>
            <td class="text-center">
              <h3>RPA Nº {{ idrpa }}</h3>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <v-text-field
                class="mt-2 mb-2"
                label="Nome Completo"
                append-icon="mdi-account"
                v-model="nome"
                readonly
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <v-text-field
                class="mt-2 mb-2"
                label="Função / Cargo"
                append-icon="mdi-contacts"
                v-model="cargoColaborador"
                readonly
                :rules="nomeCompletoRules"
              ></v-text-field>
            </td>

            <td class="text-center">
              <v-text-field
                class="mt-2 mb-2"
                label="Gestor Responsável"
                append-icon="mdi-account-convert"
                v-model="supervisorResponsavel"
                readonly
                :rules="nomeCompletoRules"
              ></v-text-field>
            </td>
          </tr>

          <tr></tr>

          <tr>
            <td colspan="2" class="text-center">
              <v-text-field
                class="mt-2 mb-2"
                label="Tipo de Fardamento"
                append-icon="mdi-tshirt-crew"
                v-model="tipoFardamento"
                readonly
                :rules="nomeCompletoRules"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                type="text"
                id="dtini"
                label="Primeiro dia de trabalho"
                v-model="dataInicio"
                @blur="calcularDias()"
                append-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                type="text"
                id="dtfim"
                label="Último dia de trabalho"
                v-model="dataFim"
                @blur="calcularDias()"
                append-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                type="text"
                label="Hora de chegada"
                v-model="horaInicio"
                append-icon="mdi-clock"
                readonly
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                type="text"
                label="Hora de saída"
                v-model="horaFim"
                append-icon="mdi-clock"
                readonly
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                label="Quadro efetivo necessário do setor"
                type="number"
                hint="nº total de func. necessário"
                persistent-hint
                v-model="numEfetivoNecessario"
                readonly
                style="max-width: 200px !important"
                :rules="numberMoreZero"
              >
              </v-text-field>
            </td>
            <td>
              <v-row justify="center">
                <v-text-field
                  class="ml-2 mr-2"
                  label="Efetivo atual disponível do setor"
                  type="number"
                  hint="nº total de func. disponíveis"
                  persistent-hint
                  v-model="numEfetivoDisponivel"
                  style="max-width: 200px !important"
                  :rules="numberMoreZero"
                  @keyup="calcularEfetivoSetor()"
                >
                </v-text-field>
              </v-row>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <v-row justify="center">
                <h3>{{percEfetivoDisponivel}} do quadro efetivo.</h3>
              </v-row>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <v-text-field
                label="Setor"
                append-icon="mdi-domain"
                v-model="setor"
                readonly
              >
              </v-text-field>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <v-textarea
                counter
                outlined
                label="Motivo"
                v-model="motivo"
                variant="outlined"
                rows="3"
                no-resize
                class="ma-2"
                readonly
              ></v-textarea>
            </td>
          </tr>

          <tr>
            <td>
              <v-text-field
                type="text"
                label="Dias trabal. / Contrat."
                v-model="diasTrabalhados"
                readonly
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                type="text"
                label="R$ Diária"
                v-model="valorDiaria"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                type="text"
                label="R$ A pagar / Negociado"
                readonly
                v-model="valorTotal"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                type="text"
                label="Atualizar Total R$"
                v-model="valorTotalAtualizado"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                type="text"
                label="% INSS / R$"
                v-model="inss"
                readonly
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                type="text"
                label="% ISS / R$"
                v-model="iss"
                readonly
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="text-center">HISTÓRICO</td>
          </tr>
          <tr>
            <td class="text-center">DATA</td>
            <td class="text-center" colspan="2">JORNADA</td>
          </tr>
          <tr v-for="item in retornoRPA" :key="item.idjornada">
            <td class="text-center">
              {{ item.datatrabalho }}
            </td>
            <td class="text-center">
              {{ item.jornada }}
            </td>
            <td class="text-center">
              <v-btn
                fab
                @click="buscarFotosInOut(item.idjornada)"
                color="primary"
                small
                class="ma-1"
                title="Mostrar fotos de entrada/saída."
                ><v-icon>mdi-eye-plus</v-icon></v-btn
              >
            </td>
          </tr>
          <tr>
            <td colspan="3" class="text-center">
              <div v-if="idNivelAcesso == 4 || idNivelAcesso == 1">
                <v-btn
                  class="ma-4"
                  color="info"
                  @click="dialogAutorizacao = true"
                  small
                  :disabled="autorizadoRH"
                  :title="
                    autorizadoRH ? 'Autorizada' : 'Clique para autorizar a RPA'
                  "
                  ><v-icon>{{
                    autorizadoRH ? "mdi-lock-open" : "mdi-lock-open"
                  }}</v-icon></v-btn
                >
                <v-btn
                  class="ma-4"
                  color="success"
                  v-if="autorizadoRH"
                  @click="dialogValidacao = true"
                  small
                  :disabled="validadoRH"
                  :title="validadoRH ? 'Validada' : 'Clique para validar a RPA'"
                  ><v-icon>{{
                    validadoRH ? "mdi-check" : "mdi-check"
                  }}</v-icon></v-btn
                >
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialogImgInOut" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Chegada/Saída</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <small>Chegada</small>
          </v-row>
          <v-row justify="center">
            <v-img :src="imgIn" height="300" width="300" class="ma-2"></v-img>
          </v-row>

          <v-row justify="center">
            <small class="mt-4">Saída</small>
          </v-row>

          <v-row justify="center">
            <v-img :src="imgOut" height="300" width="300" class="ma-2"></v-img>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn class="white--text" color="deep-orange lighten-2" small @click="closeImgDoc">Cancelar</v-btn> -->
          <v-btn color="primary" small @click="dialogImgInOut = !dialogImgInOut"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAutorizacao" max-width="400px">
      <v-card>
        <v-card-title class="text-h5">
          <v-spacer></v-spacer>Autorização RPA<v-spacer></v-spacer
        ></v-card-title>
        <v-card-text>
          <v-row justify="center">
            <h4>Confirma autorização da RPA para</h4>
          </v-row>
          <v-row justify="center">
            <h4>{{ nome }}</h4>
          </v-row>
          <v-row justify="center" class="ma-2 mt-2">
            <div v-if="autorizado">
              <h3 style="color: green">AUTORIZADO</h3>
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="exibeBtns" class="mb-2">
            <v-btn
              class="mr-2"
              fab
              color="error"
              small
              @click="dialogAutorizacao = !dialogAutorizacao"
              >NÃO</v-btn
            >
            <v-btn class="ml-2" fab color="primary" small @click="autorizaRPA()"
              >SIM</v-btn
            >
          </div>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogValidacao" max-width="400px">
      <v-card>
        <v-card-title class="text-h5">
          <v-spacer></v-spacer>Validação RPA <v-spacer></v-spacer
        ></v-card-title>
        <v-card-text>
          <v-row justify="center">
            <h4>Confirma validação da RPA para</h4>
          </v-row>
          <v-row justify="center">
            <h4>{{ nome }}</h4>
          </v-row>
          <v-row justify="center">
            <v-textarea
              v-model="observacao"
              label="Obervações"
              rows="3"
              counter="100"
              no-resize
            ></v-textarea>
          </v-row>
          <v-row justify="center" class="ma-2 mt-2">
            <div v-if="validado">
              <h3 style="color: green">VALIDADO</h3>
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="exibeBtns">
            <v-btn
              class="mr-2"
              color="error"
              small
              @click="dialogValidacao = !dialogValidacao"
              >NÃO</v-btn
            >
            <v-btn class="ml-2" color="primary" small @click="validaRPA()"
              >SIM</v-btn
            >
          </div>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import { cpf } from "cpf-cnpj-validator";
export default {
  props: ["idrpa"],
  name: "DetalhesRPA",
  data: () => ({
    retornoRPA: [],
    observacao: "",
    dialogImgInOut: false,
    dialogAutorizacao: false,
    dialogValidacao: false,
    validado: false,
    autorizado: false,
    validadoRH: false,
    autorizadoRH: false,
    percOcupacao: "",
    rpaAnormal: false,
    cargoColaborador: "",
    supervisorResponsavel: "",
    numEfetivoNecessario: 0,
    numEfetivoDisponivel: 0,
    percEfetivoDisponivel: "",
    tipoFardamento: "",
    exibeBtns: true,
    idNivelAcesso: localStorage.getItem("rpanivelacesso"),
    imgIn: "",
    imgOut: "",
    dialogOK: false,
    idColaborador: 0,
    iss: 5,
    inss: 11,
    diasTrabalhados: 0,
    valid: null,
    documentoCPF: "",
    nome: "",
    dataInicio: "",
    dataFim: "",
    horaInicio: "",
    horaFim: "",
    idSetor: 0,
    setor: "",
    motivo: "",
    valorDiaria: 0,
    valorTotal: 0,
    valorTotalAtualizado: 0,
    dataInvalida: false,
    docConferido: false,
    setores: [
      {
        id: 0,
        nome: "Alimentos e Bebidas",
      },
      {
        id: 1,
        nome: "Governança",
      },
      {
        id: 2,
        nome: "Segurança / Portaria",
      },
      {
        id: 3,
        nome: "Manutenção",
      },
      {
        id: 4,
        nome: "Recepção",
      },
      {
        id: 5,
        nome: "Tec da Informação",
      },
    ],
    nomeCompletoRules: [
      (value) => !!value || "Campo obrigatório, preencha corretamente",
      (value) => /[^0-9]/.test(value) || "Nomes não podem conter números.",
    ],
    cpfRules: [
      (value) => !!value || "Campo obrigatório, preencha corretamente",
      (value) => value.length == 11 || "11 caratéres obrigatórios",
      (value) => cpf.isValid(value) || "CPF Inválido",
    ],
    horasRules: [
      (value) => !!value || "Campo obrigatório, preencha corretamente",
      (value) => value.length == 5 || "Preencha corretamente.",
      //value =>  value.length > 3 || 'Preencha corretamente'
    ],
    dataRules: [(value) => value.length == 10 || "Preencha corretamente."],
    setorRules: [(value) => !!value || "Selecione um setor"],
    motivoRules: [
      (value) => !!value || "Informe o motivo da contratação do extra",
    ],
    diasTrabalhadosRules: [
      (value) => value > 0 || "Verifique as datas de início e fim",
    ],
    valorDiariaRules: [
      (value) => value > 0 || "Informe corretamente o valor da diária",
    ],
    valorTotalRules: [(value) => value > 0 || "Verifique os campos calculados"],

    aliquotasRules: [
      (value) => value > 0 || "As alíquotas devem ser maior que 0",
    ],
    numberMoreZero: [(value) => value > 0 || "Campo deve ser maior que zero"],
  }),
  methods: {
    displaySelected() {
      alert(this.idSetor);
    },
    async consultarRPA() {
      let obj = {
        idrpa: this.idrpa,
      };

      this.$http
        .post("/rpa/consultadetalhes", obj)
        .then((response) => {
          if (response.status == 200) {
            //console.log(JSON.stringify(response.data))
            this.retornoRPA = response.data;
            this.documentoCPF = this.retornoRPA[0].cpf;
            this.nome = this.retornoRPA[0].nome;
            this.dataInicio = this.retornoRPA[0].datainicio;
            this.dataFim = this.retornoRPA[0].datafim;
            this.horaInicio = this.retornoRPA[0].horainicioprev;
            this.horaFim = this.retornoRPA[0].horafimprev;
            this.setor = this.retornoRPA[0].setor;
            this.motivo = this.retornoRPA[0].motivo;
            this.autorizadoRH = this.retornoRPA[0].autorizadorh;
            this.validadoRH = this.retornoRPA[0].validadorh;
            this.rpaAnormal = this.retornoRPA[0].rpaanormal;
            this.percOcupacao = this.retornoRPA[0].percocupacao + "%";
            this.cargoColaborador = this.retornoRPA[0].cargotrabalhado;
            this.supervisorResponsavel = this.retornoRPA[0].gestorresponsavel;
            this.tipoFardamento = this.retornoRPA[0].tipofardamento;
            this.numEfetivoNecessario = this.retornoRPA[0].qtdeefetivonecessario;
            this.numEfetivoDisponivel = this.retornoRPA[0].qtdeefetivodisponivel;

            if( this.numEfetivoNecessario > 0 && this.numEfetivoDisponivel > 0){
              let result;
              result =
                (this.numEfetivoDisponivel * 100) / this.numEfetivoNecessario;
              result = result.toFixed(1);
              this.percEfetivoDisponivel = result + "%";
            }else{
              this.percEfetivoDisponivel = '%'
            }


            this.diasTrabalhados =
              this.retornoRPA[0].diastrabalhados +
              " / " +
              this.retornoRPA[0].diascontratados;
            this.valorDiaria = this.retornoRPA[0].diaria;
            this.valorTotal =
              this.retornoRPA[0].totalapagar +
              " / " +
              this.retornoRPA[0].totalprevisto;
            //this.valorTotalAtualizado = this.retornoRPA[0].totalprevisto
            this.iss =
              this.retornoRPA[0].aliquotaiss +
              " / " +
              this.retornoRPA[0].valoriss;
            this.inss =
              this.retornoRPA[0].aliquotainss +
              " / " +
              this.retornoRPA[0].valorinss;
            console.log(JSON.stringify(this.retornoRPA));
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async buscarFotosInOut(idjornada) {
      this.$http
        .post("/rpa/buscarfotosinout", { idjornada: idjornada })
        .then((response) => {
          if (response.status == 200) {
            // console.log(response.data[0])
            this.imgIn = response.data[0].imgcheckin;
            this.imgOut = response.data[0].imgcheckout;
            this.dialogImgInOut = true;
          } else {
            this.imgIn = "";
            this.imgOut = "";
          }
        })
        .catch((e) => {
          console.error(e);
          this.imgIn = "";
          this.imgOut = "";
        });
    },
    async salvarRPA() {
      if (this.validate()) {
        const vlrIss = (this.valorTotal / 100) * this.iss;

        const vlrInss = (this.valorTotal / 100) * this.inss;

        const rpa = {
          idcolaborador: this.idColaborador,
          datainicio: this.dataInicio,
          datafim: this.dataFim,
          horainicioprev: this.horaInicio,
          horafimprev: this.horaFim,
          idsetor: this.idSetor,
          motivo: this.motivo,
          diastotal: this.diasTrabalhados,
          valordia: this.valorDiaria,
          valortotal: this.valorTotal,
          iss: this.iss,
          inss: this.inss,
          valoriss: vlrIss.toFixed(2).replace(".", ","),
          valorinss: vlrInss.toFixed(2).replace(".", ","),
        };
        console.log(JSON.stringify(rpa));
        await this.$http
          .post("/rpa/cadastrarrpa", rpa)
          .then((response) => {
            if (response.status == 200) {
              this.retornoRPA = response.data;
              for (let index = 0; index < this.retornoRPA.length; index++) {
                let dia = "";
                let mes = "";
                let ano = "";
                const element = this.retornoRPA[index];

                element.data = element.data.substring(0, 10);
                dia = element.data.substring(8, 10);
                mes = element.data.substring(5, 7);
                ano = element.data.substring(0, 4);
                element.data = dia + "/" + mes + "/" + ano;
              }
              console.log(response.data);
              this.dialogOK = true;
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    async autorizaRPA() {
      let data = {
        idrpa: this.idrpa,
      };
      if (this.valorTotalAtualizado > 0) {
        data.valortotal = this.valorTotalAtualizado;
      }
      await this.$http
        .post("/rpa/autorizarpa", data)
        .then((response) => {
          if (response.status == 200) {
            this.autorizado = true;
            this.exibeBtns = false;
            setTimeout(() => {
              this.fechar();
              this.autorizado = false;
              this.exibeBtns = true;
            }, 2000);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async validaRPA() {
      let data = {
        idrpa: this.idrpa,
      };
      if (this.valorTotalAtualizado > 0) {
        data.valortotal = this.valorTotalAtualizado;
      }

      if (this.observacao.length > 0) {
        data.observacao = this.observacao;
      }
      console.log(JSON.stringify(data));

      await this.$http
        .post("/rpa/validarpa", data)
        .then((response) => {
          if (response.status == 200) {
            this.validado = true;
            this.exibeBtns = false;
            setTimeout(() => {
              this.fechar();
              this.autorizado = false;
              this.exibeBtns = true;
            }, 2000);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    buscarCPF() {
      this.documentoCPF = this.documentoCPF.replace(/[^0-9]/g, "");

      if (this.documentoCPF.length == 11 && cpf.isValid(this.documentoCPF)) {
        if (cpf.isValid(this.documentoCPF)) {
          this.$http
            .post("/auth/consultacpfcolaborador", {
              cpf: this.documentoCPF.replace(/[^0-9]/g, ""),
            })
            .then((response) => {
              if (response.status == 200) {
                this.idColaborador = response.data[0].idcolaborador;
                this.nome = response.data[0].nome;
                this.documentoCPF = response.data[0].cpf;
                document.getElementById("dtini").focus();

                return;
              }
            })
            .catch((e) => {
              console.error(e);
            });
        } else {
          console.log("CPF inválido");
        }
      }
    },
    async listarSetores() {
      await this.$http.get("/rpa/listarsetores").then((response) => {
        if (response.status == 200) {
          this.setores = response.data;
        }
      });
    },
    calcularDias() {
      if (this.dataInicio.length == 10 && this.dataFim.length == 10) {
        const hoje = new Date();
        console.log("Var hoje: " + hoje);
        const dtInformada = new Date(this.dataInicio);
        console.log("No campo: " + dtInformada);
        //if(dtInformada >= hoje){
        this.dataInvalida = false;
        const d1 = this.dataInicio;
        const d2 = this.dataFim;
        const diffInMs = new Date(d2) - new Date(d1);
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24) + 1;
        this.diasTrabalhados = diffInDays;
      }
    },
    calcularValorTotal() {
      if (this.diasTrabalhados > 0 && this.valorDiaria > 0) {
        this.valorTotal = this.retornoRPA[0].diastrabalhados * this.valorDiaria;
      } else {
        this.valorTotal = 0;
      }
    },
    focusCampoData() {
      if (this.dataInicio.length == 10) {
        document.getElementById("dtfim").focus();
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    fechar() {
      this.$emit("fechar");
    },
  },
  created() {
    //alert(this.idrpa)
  },
  mounted() {
    this.consultarRPA();
  },
};
</script>